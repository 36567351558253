import React from "react";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  FunctionField,
  ReferenceField
} from "react-admin";
import { ShowButton, DeleteButton } from "../../ui/Button";
import OrderFilter from "./OrderFilter";
import OrderDetails from "./OrderDetails";

const OrderList = (props) => {
  return (
    <List
        {...props}
        filters={<OrderFilter />}
        exporter={false}
        sort={{ field: 'createdate', order: 'DESC' }}
    >
      <Datagrid
          rowClick="expand"
          expand={OrderDetails}
      >
        <FunctionField render={record => console.log(record)}  />
        <TextField source="ref_order" emptyText="not provided"/>
        <TextField source="createdby" label="Created by"/>
        <DateField source="createdate" label="Create date" showTime/>
        <ReferenceField
            source="internal_routing.contract_number"
            reference="clients"
            label="Contract number"
        >
          <TextField source="contract_number" />
        </ReferenceField>

        <TextField source="packages.length" label="Packages" />
        <ShowButton basePath="/orders" />
        <DeleteButton basePath="/orders" />
      </Datagrid>
    </List>
  );
};

export default OrderList;
