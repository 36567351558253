import React from "react";
import {
  ArrayField,
  Datagrid, DateField,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  SimpleShowLayout
} from "react-admin";

import MatrixAside from "./MatrixAside";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  country: {
    color: "#6b5b95",
    padding: theme.spacing(2),
  },
  dimensionLabel: {
    "&:first-letter": {
      color: "#fff",
      fontWeight: "bold",
    },
  },
}));

const MatrixShow = (props) => {
  return (
    <Show
        title="Integration preview"
        aside={<MatrixAside />}
        {...props}
    >
      <TabbedShowLayout>
        <Tab label="MAIN">
          <TextField source="id" />
          <DateField source="createdate" label="Created at"/>
          <TextField source="createdby" label="Created by"/>
          <TextField source="name" />
          <ReferenceField
              source="nst_vendor_id"
              reference="vendors"
              emptyText="not provided"
          >
            <TextField source="code"/>
          </ReferenceField>
          <TextField source="multi_from" />
        </Tab>
        <Tab label="WEIGHTS" path="weights">
          <WeightsInfoContainer {...props} />
        </Tab>
        <Tab label="COUNTRIES" path="settings">
          <CountriesInfoContainer {...props} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const WeightsInfoContainer = (props) => {
  return (
      <SimpleShowLayout {...props}>
        {props.record.weights &&
        <ArrayField source="weights">
          <Datagrid>
            <NumberField source="from" />
            <NumberField source="to" />
            <ReferenceField source="vendor_id" reference="vendors">
              <TextField source="code" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
        }
      </SimpleShowLayout>
  )
}

const CountriesInfoContainer = (props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      {props.record.countries && props.record.countries.map((country) => (
        <Grid>
          <Typography variant="h4" align="center" className={classes.country}>
            {country.country_code}
          </Typography>
          <Grid container spacing={12}>
            <CountryTable country={country} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const CountryTable = ({ country }) => {
  const classes = useStyles();

  return (
    <Table className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="left" className={classes.dimensionLabel}>
            Depth
          </TableCell>
          <TableCell align="left" className={classes.dimensionLabel}>
            Length
          </TableCell>
          <TableCell align="left" className={classes.dimensionLabel}>
            Width
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>STD</TableCell>
          {Object.entries(country.std)
            .sort()
            .map(([dimension, data]) => {
              return (
                <TableCell align="left" component="th" scope="row">
                  {data.min} - {data.max}
                </TableCell>
              );
            })}
        </TableRow>
        <TableRow>
          <TableCell>NST</TableCell>
          {Object.entries(country.nst)
            .sort()
            .map(([dimension, data]) => {
              return (
                <TableCell align="left" component="th" scope="row">
                  {data.min} - {data.max}
                </TableCell>
              );
            })}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default MatrixShow;
