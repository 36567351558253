import React from "react";
import {
    TextField,
    SimpleShowLayout,
    DateField,
    ReferenceField
} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "& .MuiFormLabel-root": {
            color: "white",
            fontWeight: 700
        },
        "& .MuiTableSortLabel-root": {
            color: "white",
            fontWeight: 700
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        textAlign: "left",
        color: theme.palette.text.secondary,
    },
}));

const PrimaryInfo = (props) => {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <Paper className={classes.paper }>
                <h2>Info</h2>
                <SimpleShowLayout {...props}>
                    <TextField source="id" />
                    <DateField source="createdate" label="Created at" showTime/>
                    <TextField source="createdby" label="Created by"/>
                    <TextField source="display_name" label="Name"/>
                    <TextField source="contract_number" label="Contract number"/>
                    <ReferenceField
                        source="matrix_id"
                        reference="matrices"
                        label="Matrix ID"
                    >
                        <TextField source="id" />
                    </ReferenceField>
                    <DateField source="lastupdate" label="Last update" showTime emptyText="no update"/>
                    <TextField source="updatedby" label="Update by" emptyText="no update"/>
                </SimpleShowLayout>
            </Paper>
        </Grid>
    );
}

const ClientDetails = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <PrimaryInfo {...props} />
            </Grid>
        </div>
    );
};

export default ClientDetails;