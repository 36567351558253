import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    ReferenceInput,
    SelectInput,
    required,
    minLength,
    maxLength,
    useNotify,
    useRedirect
} from "react-admin";

const message = "please enter a value";
const validateName = [required(message), minLength(1), maxLength(40)];
const validateRequired = [required(message)];

const ClientEdit = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify(`Changes in client saved`)
        redirect('/clients');
    };
    return (
        <Edit
            title="Edycja klienta"
            warnWhenUnsavedChanges
            onSuccess={onSuccess}
            undoable={false}
            {...props}
        >
            <SimpleForm submitOnEnter={false}>
                <TextInput
                    label="ID"
                    source="id"
                    disabled
                />
                <DateInput
                    label="Create date"
                    source="createdate"
                    disabled
                />
                <TextInput
                    label="Created by"
                    source="createdby"
                    disabled
                />
                <TextInput
                    source="display_name"
                    validate={validateName}
                />
                <TextInput
                    source="contract_number"
                    validate={validateRequired}
                />
                <ReferenceInput
                    label="Matrix ID"
                    source="matrix_id"
                    reference="matrices"
                >
                    <SelectInput
                        optionText="display_name"
                        validate={validateRequired}
                    />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

export default ClientEdit;