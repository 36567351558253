import Typography from "@material-ui/core/Typography";

const MatrixAside = () => (
  <div style={{ width: 200, margin: "1em" }}>
    <Typography variant="title">Szczegóły matryc</Typography>
    <Typography variant="body1">
      Matryce łączą integracje z kontraktami.
    </Typography>
  </div>
);

export default MatrixAside;
