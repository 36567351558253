import React from "react";
import {
    Datagrid,
    DateField,
    List,
    TextField
} from "react-admin";
import { ShowButton, DeleteButton } from "../../ui/Button";
import LoggingFilter from "./LoggingFilter";
import LoggingDetails from "./LoggingDetails";

const LoggingList = (props) => {
  return (
    <List
        {...props}
        filters={<LoggingFilter />}
        exporter={false}
        sort={{ field: 'createdate', order: 'DESC' }}
    >
      <Datagrid
          rowClick="expand"
          expand={LoggingDetails}
      >
        <TextField source="id" />
        <DateField source="createdate" />
        <TextField source="message" />
        <ShowButton basePath="/logging" />
        <DeleteButton basePath="/logging" />
      </Datagrid>
    </List>
  );
};

export default LoggingList;
