import React from "react";
import {
    Filter,
    TextInput,
    DateInput
} from "react-admin";

const ParcelFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Created by" source="createdby" />
        <DateInput label="Create date" source="createdate" />
        <TextInput label="Order ID" source="order" />
        <TextInput label="Latest status" source="latest_status.status" />
    </Filter>
);

export default ParcelFilter;