import Typography from "@material-ui/core/Typography";

const VendorAside = () => (
  <div style={{ width: 200, margin: "1em" }}>
    <Typography variant="title">Szczegóły integracji</Typography>
    <Typography variant="body1">
      Aby dodać integrację skontakuj się z adminem.
    </Typography>
  </div>
);

export default VendorAside;
