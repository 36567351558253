import React from "react";
import {
    Create,
    maxLength,
    minLength,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect
} from "react-admin";
import { JsonInput } from "react-admin-json-view";
import jsonTheme from "../../ui/JsonTheme";

//inputs validation
const message = "please enter a value";
const validateName = [required(message), minLength(1), maxLength(40)];
const validateDescription = [required(message), minLength(1), maxLength(200)];

const VendorCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
      notify(`New vendor saved`);
      redirect("/vendors");
  };

  return (
    <Create
        title="Tworzenie integracji"
        warnWhenUnsavedChanges
        onSuccess={onSuccess}
        {...props}
    >
      <SimpleForm submitOnEnter={false}>
        <TextInput source="code" validate={validateName}/>
        <TextInput source="display_name" validate={validateName}/>
        <TextInput
          source="description"
          multiline
          resettable
          validate={validateDescription}
        />
        <JsonInput
          source="settings"
          // validate={[required(message)]}
          jsonString={false} // Set to true if the value is a string, default: false
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
            theme: jsonTheme
          }}
        />
      </SimpleForm>
    </Create>
  );
};

export default VendorCreate;
