import * as React from "react";
import { CreateButton as CreateBTN } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    button: {
        fontWeight: "bold",
        color: "yellow",
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        "& svg": { color: "yellow" },
        boxSizing: "border-box",
        margin: "5%"
    },
});

const CreateButton = (props) => {
    const classes = useStyles();
    return (
        <CreateBTN
            {...props}
            className={classes.button}
            basePath={props.content}
            label={"Add " + props.label}
        />
    )
};
export default CreateButton;
