import {
    Show,
    TextField,
    EmailField,
    TabbedShowLayout,
    Tab,
    DateField
} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import UserAside from "./UserAside";
import React from "react";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        "& .MuiFormLabel-root": {
            color: "white",
            fontWeight: 700
        },
        "& .MuiTableSortLabel-root": {
            color: "white",
            fontWeight: 700
        },
        "& .MuiFormControl-root": {
            marginRight: 15
        },
        "& .MuiCardContent-root": {
            padding: 0
        },
        "& .MuiTable-root": {
            marginBottom: 16
        }
    }
}));

const UserShow = (props) => {
    const classes = useStyles();
    return (
        <Show
            title="User preview"
            aside={<UserAside />}
            {...props}
        >
            <TabbedShowLayout className={classes.root}>
                <Tab label="MAIN">
                    <TextField source="id" />
                    <DateField source="createdate" label="Created at" showTime/>
                    <TextField source="createdby" label="Created by"/>
                    <DateField source="lastupdate" label="Last update" showTime emptyText="no update"/>
                    <TextField source="updatedby" label="Update by" emptyText="no update"/>
                </Tab>
                <Tab label="DETAILS">
                    <TextField source="firstName" label="First name" emptyText="not provided"/>
                    <TextField source="lastName" label="Last name" emptyText="not provided"/>
                    <TextField source="role" emptyText="not provided"/>
                    <EmailField source="email" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default UserShow;
