import Typography from "@material-ui/core/Typography";

const ParcelAside = () => (
    <div style={{ width: 200, margin: "1em" }}>
        <Typography variant="title">Szczegóły parceli</Typography>
        <Typography variant="body1">
            Skontakuj się z adminem.
        </Typography>
    </div>
);

export default ParcelAside;
