// Material-UI
import { createMuiTheme } from "@material-ui/core/styles";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import UserIcon from "@material-ui/icons/People";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import ReportIcon from '@material-ui/icons/Report';
// Firebase and react-admin
import firebase from "firebase";
import {
  Admin,
  Resource
} from "react-admin";
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from "react-admin-firebase";
// Matrices
import MatrixShow from "./components/Matrix/MatrixShow";
import MatrixCreate from "./components/Matrix/MatrixCreate";
import MatrixEdit from "./components/Matrix/MatrixEdit";
import MatrixList from "./components/Matrix/MatrixList";
// Order
// import OrderCreate from "./components/Order/OrderCreate";
// import OrderEdit from "./components/Order/OrderEdit";
import OrderList from "./components/Order/OrderList";
import OrderShow from "./components/Order/OrderShow";
// Parcel
import ParcelList from "./components/Parcel/ParcelList";
import ParcelShow from "./components/Parcel/ParcelShow";
// Post
import PostCreate from "./components/Post/PostCreate";
import PostEdit from "./components/Post/PostEdit";
import PostList from "./components/Post/PostList";
// User
import UserList from "./components/User/UserList";
// import UserCreate from "./components/User/UserCreate";
// import UserEdit from "./components/User/UserEdit";
import UserShow from "./components/User/UserShow";
// Vendor
import VendorCreate from "./components/Vendor/VendorCreate";
import VendorList from "./components/Vendor/VendorList";
import VendorShow from "./components/Vendor/VendorShow";
// Client
import ClientList from "./components/Client/ClientList";
import ClientShow from "./components/Client/ClientShow";
import ClientCreate from "./components/Client/ClientCreate";
import ClientEdit from "./components/Client/ClientEdit";
// Loggings
import LoggingList from "./components/Logging/LoggingList";
// Login
import Dashboard from "./pages/Dashboard/Dashboard.js";
import CustomLoginPage from "./pages/LoginPage/CustomLoginPage";
import NotFound from "./pages/NotFound/NotFound.js";
import LoggingShow from "./components/Logging/LoggingShow";
import VendorEdit from "./components/Vendor/VendorEdit";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};
const options = {
  logging: true,
  rootRef: "/",
};
const firebaseApp = firebase.initializeApp(config);
const dataProvider = FirebaseDataProvider(config, {
  logging: true,
  // rootRef: 'rootrefcollection/QQG2McwjR2Bohi9OwQzP',
  app: firebaseApp,
  // watch: ['posts'];
  // dontwatch: ['comments'];
  persistence: "local",
  // disableMeta: true
  dontAddIdFieldToDoc: true,
  lazyLoading: {
    enabled: false,
  },
  firestoreCostsLogger: {
    enabled: true,
  },
});
const authProvider = FirebaseAuthProvider(config, options);
const theme = createMuiTheme({
  palette: {
    type: "dark", // Switching the dark mode on is a single property value change.
    primary: {
      main: "rgb(252, 146, 158)",
    },
  },
});

function App() {
  return (
    <Admin
      theme={theme}
      dashboard={Dashboard}
      catchAll={NotFound}
      loginPage={CustomLoginPage}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      {(permissions) => {
        return [
          <Resource
            name="orders"
            list={OrderList}
            // create={OrderCreate}
            // edit={OrderEdit}
            show={OrderShow}
            icon={AccountTreeIcon}
          />,
          <Resource
              name="parcels"
              list={ParcelList}
              show={ParcelShow}
              icon={AllInboxIcon}
          />,
          <Resource
            name="matrices"
            list={MatrixList}
            create={MatrixCreate}
            edit={MatrixEdit}
            show={MatrixShow}
            icon={ImportantDevicesIcon}
          />,
          <Resource
            name="vendors"
            list={VendorList}
            create={VendorCreate}
            edit={VendorEdit}
            show={VendorShow}
            icon={LocalShippingIcon}
          />,
          <Resource
            name="clients"
            list={ClientList}
            create={ClientCreate}
            edit={ClientEdit}
            show={ClientShow}
            icon={AssignmentIndIcon}
          />,
          <Resource
            name="users"
            list={UserList}
            // create={UserCreate}
            // edit={UserEdit}
            show={UserShow}
            icon={UserIcon}
          />,
          <Resource
              name="logging"
              list={LoggingList}
              show={LoggingShow}
              icon={ReportIcon}
          />,
          <Resource
            name="posts"
            list={PostList}
            create={PostCreate}
            edit={PostEdit}
          />,
        ];
      }}
    </Admin>
  );
}

export default App;