import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    ReferenceInput,
    required,
    minLength,
    maxLength,
    useNotify,
    useRedirect
} from "react-admin";

//inputs validation
const message = "please enter a value";
const validateName = [required(message), minLength(1), maxLength(40)];
const validateRequired = [required(message)];

const ClientCreate = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify(`New client saved`);
        redirect("/clients");
    };
    return (
        <Create
            title="Adding new client"
            warnWhenUnsavedChanges
            onSuccess={onSuccess}
            {...props}
        >
            <SimpleForm submitOnEnter={false}>
                <TextInput source="display_name" validate={validateName} />
                <TextInput source="contract_number" validate={validateRequired} />
                <ReferenceInput
                    label="Matrix ID"
                    source="matrix_id"
                    reference="matrices"
                >
                    <SelectInput optionText="display_name" validate={validateRequired} />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export default ClientCreate;