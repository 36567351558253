import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  useNotify,
  useRedirect,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  sizeInput: {
    width: 150,
  },
  customInput: {
    float: "left",
    width: 80,
    marginRight: "10px",
  },
  customLabel: {
    float: "left",
    width: 80,
    textAlign: "center",
    lineHeight: "65px",
  },
  customMainLabel: {
    marginBottom: "10px",
    display: "block",
    // textDecoration: "underline wavy"
  },
}));
//inputs validation
const message = "please enter a value";
const validateName = [required(message), minLength(1), maxLength(40)];
const validateRequired = [required(message)];
const validateDescription = [required(message), minLength(1), maxLength(200)];
const validateMultiFrom = [required(message), minValue(2), number()];
const validateNumbers = [
  required(message),
  minValue(0),
  maxValue(999),
  number(),
];

const MatrixCreate = (props) => {
  const notify = useNotify();
  // const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify(`New matrix saved`);
    redirect("/matrices");
    // refresh();
  };
  // const onFailure = (error) => {
  //     notify(`Could not save new matrix: ${error.message}`)
  //     refresh();
  // };

  return (
    <Create
      title="Dodawanie matrycy"
      warnWhenUnsavedChanges
      onSuccess={onSuccess}
      {...props}
    >
      {/*<Create
    onFailure={onFailure}
    >*/}
      <SimpleForm submitOnEnter={false}>
        <TextInput source="name" validate={validateName} />
        <TextInput source="display_name" validate={validateName} />
        <ReferenceInput
          label="Contract"
          source="contract_number"
          reference="clients"
        >
          <SelectInput optionText="display_name" validate={validateRequired} />
        </ReferenceInput>
        <ReferenceInput
          label="Non-standard vendor"
          source="nst_vendor_id"
          reference="vendors"
        >
          <SelectInput optionText="code" validate={validateRequired} />
        </ReferenceInput>
        <TextInput
          source="description"
          multiline
          resettable
          validate={validateDescription}
        />
        <NumberInput source="multi_from" validate={validateMultiFrom} />
        <WeightsForm />
        <CountriesForm />
      </SimpleForm>
    </Create>
  );
};

const WeightsForm = () => {
  return (
    <ArrayInput source="weights" validate={validateRequired}>
      <SimpleFormIterator>
        <NumberInput
          initialValue={0}
          label="from"
          source="from"
          validate={validateNumbers}
        />
        <NumberInput
          initialValue={0}
          label="to"
          source="to"
          validate={validateNumbers}
        />
        <ReferenceInput label="vendor" source="vendor_id" reference="vendors">
          <SelectInput optionText="code" validate={validateRequired} />
        </ReferenceInput>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const CountriesForm = () => {
  const classes = useStyles();
  const countries = [
    {
      id: "FR",
      name: "France",
    },
    {
      id: "DE",
      name: "Germany",
    },
    {
      id: "PL",
      name: "Poland",
    },
    {
      id: "CZ",
      name: "Czech Republic",
    },
    {
      id: "NL",
      name: "Netherlands",
    },
    {
      id: "UA",
      name: "Ukraine",
    },
  ];

  return (
    <ArrayInput source="countries" validate={validateRequired}>
      <SimpleFormIterator>
        <SelectInput
          label="country"
          source="country_code"
          choices={countries}
          validate={validateRequired}
        />

        <span className={classes.customMainLabel}>STANDARD</span>
        <span className={classes.customLabel}>Size D</span>
        <NumberInput
          initialValue={0}
          label="min"
          source="std.size_d.min"
          validate={validateNumbers}
          className={classes.customInput}
        />
        <NumberInput
          initialValue={0}
          label="max"
          source="std.size_d.max"
          validate={validateNumbers}
          className={classes.customInput}
        />
        <div style={{ display: "block", clear: "both" }} />
        <span className={classes.customLabel}>Size L</span>
        <NumberInput
          initialValue={0}
          label="min"
          source="std.size_l.min"
          validate={validateNumbers}
          className={classes.customInput}
        />
        <NumberInput
          initialValue={0}
          label="max"
          source="std.size_l.max"
          validate={validateNumbers}
          className={classes.customInput}
        />
        <div style={{ display: "block", clear: "both" }} />
        <span className={classes.customLabel}>Size W</span>
        <NumberInput
          initialValue={0}
          label="min"
          source="std.size_w.min"
          validate={validateNumbers}
          className={classes.customInput}
        />
        <NumberInput
          initialValue={0}
          label="max"
          source="std.size_w.max"
          validate={validateNumbers}
          className={classes.customInput}
        />
        <div style={{ display: "block", clear: "both" }} />

        <span className={classes.customMainLabel}>NON-STANDARD</span>
        <span className={classes.customLabel}>Size D</span>
        <NumberInput
          initialValue={0}
          label="min"
          source="nst.size_d.min"
          validate={validateNumbers}
          className={classes.customInput}
        />
        <NumberInput
          initialValue={0}
          label="max"
          source="nst.size_d.max"
          validate={validateNumbers}
          className={classes.customInput}
        />
        <div style={{ display: "block", clear: "both" }} />
        <span className={classes.customLabel}>Size L</span>
        <NumberInput
          initialValue={0}
          label="min"
          source="nst.size_l.min"
          validate={validateNumbers}
          className={classes.customInput}
        />
        <NumberInput
          initialValue={0}
          label="max"
          source="nst.size_l.max"
          validate={validateNumbers}
          className={classes.customInput}
        />
        <div style={{ display: "block", clear: "both" }} />
        <span className={classes.customLabel}>Size W</span>
        <NumberInput
          initialValue={0}
          label="min"
          source="nst.size_w.min"
          validate={validateNumbers}
          className={classes.customInput}
        />
        <NumberInput
          initialValue={0}
          label="max"
          source="nst.size_w.max"
          validate={validateNumbers}
          className={classes.customInput}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default MatrixCreate;
