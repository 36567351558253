import {Filter, TextInput} from "react-admin";
import React from "react";

const MatrixFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Contract number" source="contract_number" />
        <TextInput label="Code" source="code" />
        <TextInput label="Multi from" source="multi_from" />
        <TextInput label="Nst vendor id" source="nst_vendor_id" />
    </Filter>
);

export default MatrixFilter;