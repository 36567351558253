import React from 'react';
import {SimpleShowLayout} from "react-admin";
import {JsonField} from "react-admin-json-view";
import jsonTheme from "../../ui/JsonTheme";

const VendorSettings = (props) => {
    return (
        <SimpleShowLayout {...props} >
            <JsonField
                source="settings"
                addLabel={false}
                jsonString={false} // Set to true if the value is a string, default: false
                reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: false,
                    enableClipboard: false,
                    displayDataTypes: false,
                    theme: jsonTheme
                }}
            />
        </SimpleShowLayout>
    );
};

export default VendorSettings;