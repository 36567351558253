import Typography from "@material-ui/core/Typography";

const OrderAside = () => (
  <div style={{ width: 200, margin: "1em" }}>
    <Typography variant="title">Szczegóły zamówienia</Typography>
    <Typography variant="body1">
      Na tej liście znjdują się informacje o zamówieniu trafiającym do Enki
      poprzez API.
    </Typography>
  </div>
);

export default OrderAside;
