import Typography from "@material-ui/core/Typography";

const LoggingAside = () => {
    return (
        <div style={{ width: 250, margin: "1em" }}>
            <Typography variant="title">Szczegóły </Typography>
            <Typography variant="body1">
                <p>Po więcej szczegółów skontaktuj się z adminem</p>
            </Typography>
        </div>
    );
};

export default LoggingAside;