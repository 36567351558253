import React from "react";
import {
    Datagrid,
    DateField,
    List,
    ReferenceField,
    TextField,
} from "react-admin";
import ParcelFilter from "./ParcelFilter";
import ParcelDetails from "./ParcelDetails";
import {
    DeleteButton,
    ShowButton,
    CustomButton
} from "../../ui/Button";
import Tooltip from '@material-ui/core/Tooltip';
import LabelIcon from '@material-ui/icons/Label';

const ParcelList = (props) => {

    const TooltipWrapper = React.forwardRef(({children, ...props}, ref) => (
        <div ref={ref}>{React.cloneElement(children, props)}</div>
    ));
    const MyTooltip = ({children, ...props}) => (
        <Tooltip {...props}>
            <TooltipWrapper>{children}</TooltipWrapper>
        </Tooltip>
    );
    const StatusField = ({ record }) => {
        return (
            <MyTooltip title={record.latest_status.message} aria-label="add">
                <TextField source="latest_status.status" record={record} />
            </MyTooltip>
        )
    }
    const handleClick = (e, record) => {
        e.stopPropagation()
        console.log("https://base64.guru/converter/decode/pdf");
        console.log("First label data format:",record.labels[0].label_format);
        console.log(record.labels[0].label_data);
    }

    return (
    <List
        {...props}
        filters={<ParcelFilter />}
        exporter={false}
    >
      <Datagrid
          rowClick="expand"
          expand={ParcelDetails}
      >
        <ReferenceField source="order" reference="orders">
           <TextField source="id" />
        </ReferenceField>
        <DateField source="createdate" label="Create date"/>
        <TextField source="createdby" label="Created by"/>
        <StatusField label="Latest status"/>
        <CustomButton handleClick={handleClick} innerText="LABEL" >
          <LabelIcon />
        </CustomButton>
        <ShowButton basePath="/parcels" />
        <DeleteButton basePath="/parcels" />
      </Datagrid>
    </List>
  );
};

export default ParcelList;