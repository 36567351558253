import Typography from "@material-ui/core/Typography";

const ClientAside = () => (
    <div style={{ width: 200, margin: "1em" }}>
        <Typography variant="title">Szczegóły klienta</Typography>
        <Typography variant="body1">
            Na tej liście znajdują się informacje o kliencie.
        </Typography>
    </div>
);

export default ClientAside;
