import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { usePermissions, Title } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { WidgetList } from "../../ui/Widget/WidgetList";
import CreateButton from "../../ui/Widget/WidgetButtons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function Dashboard(props) {
  const classes = useStyles();
  const { loading, permissions } = usePermissions();

  return loading ? (
    <div>Please, wait for data...</div>
  ) : (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <Title title="Welcome to the administration" />
            <CardContent>Welcome to Enki, {permissions.email}</CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <WidgetList
              {...props}
              content={"parcels"}
              source={"latest_status.message"}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <WidgetList {...props} content={"orders"} source={"ref_order"} />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <WidgetList
              {...props}
              content={"clients"}
              source={"display_name"}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <WidgetList
              {...props}
              content={"vendors"}
              source={"display_name"}
              label={"operators"}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <WidgetList
              {...props}
              content={"matrices"}
              source={"display_name"}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <WidgetList
              {...props}
              content={"logging"}
              source={"message"}
              label={"logs"}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <WidgetList {...props} content={"posts"} source={"title"} />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <CreateButton {...props} content={"vendors"} label={"operator"} />
            <CreateButton {...props} content={"matrices"} label={"matrix"} />
            <CreateButton {...props} content={"clients"} label={"client"} />
            <CreateButton {...props} content={"posts"} label={"post"} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
