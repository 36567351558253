import * as React from "react";
import { DeleteButton as DeleteBTN } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    fontWeight: "bold",
    color: "tomato",
    // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
    "& svg": { color: "tomato" },
  },
});

const DeleteButton = (props) => {
  const classes = useStyles();
  return <DeleteBTN className={classes.button} {...props} />;
};
export default DeleteButton;
