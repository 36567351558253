import React from "react";
import {
    DateInput,
    Edit,
    maxLength,
    minLength,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect
} from "react-admin";
import {JsonInput} from "react-admin-json-view";
import jsonTheme from "../../ui/JsonTheme";

//inputs validation
const message = "please enter a value";
const validateName = [required(message), minLength(1), maxLength(40)];
const validateDescription = [required(message), minLength(1), maxLength(200)];

const VendorEdit = (props) => {
   const notify = useNotify();
   const redirect = useRedirect();
   const onSuccess = () => {
       notify(`Changes in vendor saved`)
       redirect('/vendors');
   };

  return (
    <Edit
        title="Edycja integracji"
        warnWhenUnsavedChanges
        onSuccess={onSuccess}
        undoable={false}
        {...props}
    >
      <SimpleForm submitOnEnter={false}>
          <TextInput
              label="ID"
              source="id"
              disabled
          />
          <DateInput
              label="Created at"
              source="createdate"
              disabled
          />
          <TextInput
              label="Created by"
              source="createdby"
              disabled
          />
          <TextInput
              source="code"
              validate={validateName}
          />
          <TextInput
              source="display_name"
              validate={validateName}
          />
          <TextInput
              source="description"
              multiline
              resettable
              validate={validateDescription}
          />
          <JsonInput
              source="settings"
              // validate={[required(message)]}
              jsonString={false} // Set to true if the value is a string, default: false
              reactJsonOptions={{
                  // Props passed to react-json-view
                  name: null,
                  collapsed: true,
                  enableClipboard: false,
                  displayDataTypes: false,
                  theme: jsonTheme
              }}
          />
      </SimpleForm>
    </Edit>
  );
};

export default VendorEdit;
