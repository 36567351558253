import React from "react";
import {
  Tab,
  Show,
  TextField,
  TabbedShowLayout,
  DateField
} from "react-admin";
import VendorAside from "./VendorAside";
import VendorSettings from "./VendorSettings";

const VendorShow = (props) => {
  return (
    <Show title="Integration preview" aside={<VendorAside />} {...props} >
      <TabbedShowLayout>
        <Tab label="MAIN">
          <TextField source="id" />
          <DateField source="createdate" label="Created at"/>
          <TextField source="createdby" label="Created by"/>
          <TextField source="code" />
          <TextField source="display_name" />
        </Tab>
        <Tab label="SETTINGS" path="settings">
          <VendorSettings {...props} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default VendorShow;
