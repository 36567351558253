import Grid from "@material-ui/core/Grid";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
    DateField,
    ReferenceField,
    SimpleShowLayout,
    TextField,
    FunctionField,
    NumberField,
    ArrayField,
    Datagrid
} from "react-admin";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "& .MuiFormLabel-root": {
            color: "white",
            fontWeight: 700
        },
        "& .MuiTableSortLabel-root": {
            color: "white",
            fontWeight: 700
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        textAlign: "left",
        color: theme.palette.text.secondary,
    },
}));

const PrimaryInfo = (props) => {
    const classes = useStyles();
    return (
        <Grid item xs={3}>
            <Paper className={classes.paper }>
                <h2>Info</h2>
                <SimpleShowLayout {...props}>
                    <TextField source="id" />
                    <DateField source="createdate" label="Created at"/>
                    <TextField source="createdby" label="Created by"/>
                    <ReferenceField source="order" reference="orders">
                        <TextField source="id" />
                    </ReferenceField>
                    <FunctionField
                        label="Latest status"
                        render={record =>
                            `${record.latest_status.status}: ${record.latest_status.message}`
                        }
                    />
                </SimpleShowLayout>
            </Paper>
        </Grid>
    );
}

const DetailsInfo = (props) => {
    const classes = useStyles();
    return (
        <Grid item xs={2}>
            <Paper className={classes.paper}>
                <h2>Details</h2>
                <SimpleShowLayout {...props}>
                    <TextField source="details.ref_number" label="Ref number"/>
                    <NumberField source="details.weight" label="Weight"/>
                    <NumberField source="details.size_l" label="Size L"/>
                    <NumberField source="details.size_w" label="Size W"/>
                    <NumberField source="details.size_d" label="Size D"/>
                    <TextField source="details.content" multiline label="Content"/>
                    <NumberField source="details.value" label="Value"/>
                </SimpleShowLayout>
            </Paper>
        </Grid>
    );
}

const HistoryInfo = (props) => {
    const classes = useStyles();

    return (
        <Grid item xs={3}>
            <Paper className={classes.paper}>
                <h2>History</h2>
                <SimpleShowLayout {...props}>
                    <ArrayField source="history" addLabel={false}>
                        <Datagrid>
                            <TextField source="status" />
                            <TextField source="message" />
                        </Datagrid>
                    </ArrayField>
                </SimpleShowLayout>
            </Paper>
        </Grid>
    );
};

const TrackingInfo = (props) => {
    const classes = useStyles();

    return (
        <Grid item xs={4}>
            <Paper className={classes.paper}>
                <h2>Tracking</h2>
                <SimpleShowLayout {...props}>
                    <ArrayField source="tracking" addLabel={false}>
                        <Datagrid>
                            <TextField source="label" />
                            <TextField source="number_1" label="Number"/>
                            <TextField source="type" />
                        </Datagrid>
                    </ArrayField>
                </SimpleShowLayout>
            </Paper>
        </Grid>
    );
};

const ParcelDetails = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <PrimaryInfo {...props} />
                <DetailsInfo {...props} />
                <HistoryInfo {...props} />
                <TrackingInfo {...props} />
            </Grid>
        </div>
    );
};

export default ParcelDetails;
