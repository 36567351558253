import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  NumberField,
  ReferenceField,
  SimpleShowLayout,
  TextField,
} from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiFormLabel-root": {
      color: "white",
      fontWeight: 700
    },
    "& .MuiTableSortLabel-root": {
      color: "white",
      fontWeight: 700
    }
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  country: {
    color: "#6b5b95",
    padding: theme.spacing(2),
  },
  dimensionLabel: {
    "&:first-letter": {
      color: "#fff",
      fontWeight: 800,
      fontSize: "120%"
    },
  },
}));

const PrimaryInfo = (props) => {
  const classes = useStyles();
  return (
    <Grid item xs={4}>
      <Paper className={classes.paper}>
        <h2>Info</h2>
        <SimpleShowLayout {...props}>
          <TextField source="id" />
          <TextField source="name" />
          <DateField source="createdate" label="Created at"/>
          <TextField source="createdby" label="Created by"/>
        </SimpleShowLayout>
      </Paper>
    </Grid>
  );
};

const NSTInfo = (props) => {
  const classes = useStyles();
  return (
    <Grid item xs={4}>
      <Paper className={classes.paper}>
        <h2>Non-standard vendor</h2>
        <SimpleShowLayout {...props}>
          <ReferenceField
              source="nst_vendor_id"
              reference="vendors"
              emptyText="not provided"
          >
            <TextField source="code"/>
          </ReferenceField>
          <NumberField source="multi_from" />
        </SimpleShowLayout>
      </Paper>
    </Grid>
  );
};

const WeightsInfo = (props) => {
  const classes = useStyles();
  return (
    <Grid item xs={4}>
      <Paper className={classes.paper}>
        <h2>Weights</h2>
        <SimpleShowLayout {...props}>
          <ArrayField source="weights">
            <Datagrid>
              <NumberField source="from" />
              <NumberField source="to" />
              <ReferenceField
                  source="vendor_id"
                  reference="vendors"
                  emptyText="not provided"
              >
                <TextField source="code" emptyText="not provided"/>
              </ReferenceField>
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </Paper>
    </Grid>
  );
};

const CountriesInfoContainer = (props) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <h2>Countries</h2>
        {props.record.countries.map((country) => (
          <>
            <Typography variant="h4" align="center" className={classes.country}>
              {country.country_code}
            </Typography>
            <Grid container spacing={12}>
              <CountryTable country={country} />
            </Grid>
          </>
        ))}
      </Paper>
    </Grid>
  );
};

const CountryTable = ({ country }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left" className={classes.dimensionLabel}>
              Depth
            </TableCell>
            <TableCell align="left" className={classes.dimensionLabel}>
              Length
            </TableCell>
            <TableCell align="left" className={classes.dimensionLabel}>
              Width
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>STD</TableCell>
            {Object.entries(country.std)
              .sort()
              .map(([dimension, data]) => {
                return (
                  <TableCell align="left" component="th" scope="row">
                    {data.min} - {data.max}
                  </TableCell>
                );
              })}
          </TableRow>
          <TableRow>
            <TableCell>NST</TableCell>
            {Object.entries(country.nst)
              .sort()
              .map(([dimension, data]) => {
                return (
                  <TableCell align="left" component="th" scope="row">
                    {data.min} - {data.max}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};

const MatrixDetails = (props) => {
  const classes = useStyles();
  const {weights, countries} = props.record;
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <PrimaryInfo {...props} />
        <NSTInfo {...props} />
        {weights && <WeightsInfo {...props} />}
        {countries&& <CountriesInfoContainer {...props} />}
      </Grid>
    </div>
  );
};

export default MatrixDetails;
