import {
    SimpleShowLayout,
    TextField,
    DateField
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import VendorSettings from "./VendorSettings";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "& .MuiFormLabel-root": {
            color: "white",
            fontWeight: 700
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        textAlign: "left",
        color: theme.palette.text.secondary,
    },
}));

const PrimaryInfo = (props) => {
    const classes = useStyles();
    return (
        <Grid item xs={5}>
            <Paper className={classes.paper}>
                <h2>Info</h2>
                <SimpleShowLayout {...props}>
                    <TextField source="id" />
                    <DateField source="createdate" label="Created at"/>
                    <TextField source="createdby" label="Created by"/>
                </SimpleShowLayout>
            </Paper>
        </Grid>
    );
}

const SettingsInfo = (props) => {
    const classes = useStyles();
    return (
        <Grid item xs={7}>
            <Paper className={classes.paper}>
                <h2>Settings</h2>
                <VendorSettings {...props} />
            </Paper>
        </Grid>
    );
}

const VendorDetails = (props) => {
  const classes = useStyles();
  return (
      <div className={classes.root}>
          <Grid container spacing={3}>
              <PrimaryInfo {...props} />
              <SettingsInfo {...props} />
          </Grid>
      </div>
  );
};

export default VendorDetails;
