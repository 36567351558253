import React from "react";
import {
    Datagrid,
    DateField,
    List,
    TextField,
    ReferenceField
} from "react-admin";
import {ShowButton, DeleteButton, EditButton} from "../../ui/Button";
import ClientFilter from "./ClientFilter";
import ClientDetails from "./ClientDetails";

const ClientList = (props) => {
    return (
        <List
            {...props}
            filters={<ClientFilter />}
            exporter={false}
            sort={{ field: 'createdate', order: 'DESC' }}
        >
            <Datagrid
                rowClick="expand"
                expand={ClientDetails}
            >
                <TextField source="display_name" label="Name" />
                <TextField source="createdby" label="Created by"/>
                <DateField source="createdate" label="Create date" showTime/>
                <ReferenceField
                    source="matrix_id"
                    reference="matrices"
                    label="Matrix ID"
                >
                    <TextField source="id" />
                </ReferenceField>
                <ShowButton basePath="/clients" />
                <EditButton basePath="/clients" />
                <DeleteButton basePath="/clients" />
            </Datagrid>
        </List>
    );
};

export default ClientList;