import React from "react";
import { Edit, SimpleForm, TextInput, DateInput } from "react-admin";

const PostEdit = (props) => {
  return (
    <Edit title="Edycja artykułu" {...props}>
      <SimpleForm>
        <TextInput label="ID" disabled source="id" />
        <TextInput label="Tytuł" source="title" />
        <TextInput label="Treść" multiline source="body" />
        <DateInput label="Opublikowano" source="publishedAt" />
      </SimpleForm>
    </Edit>
  );
};

export default PostEdit;
