import Typography from "@material-ui/core/Typography";

const UserAside = () => (
    <div style={{ width: 400, margin: "1em" }}>
        <Typography variant="title">Szczegóły użytkownika</Typography>
        <Typography variant="body1">
            <p>Na tej liście znajdują się informacje o użytkowniku.</p>
            <ul>
                <li>Super Admin (slug: ‘superadmin’)  – somebody with access to the site network administration features and all other features.</li>
                <li>Administrator (slug: ‘administrator’) – somebody who has access to all the administration features within a single site.</li>
                <li>Editor (slug: ‘editor’) – somebody who can publish and manage data including the data of other users.</li>
                <li>Regular User (slug: ‘regular’)</li>
            </ul>
            <p>Userów nie dodajemy z panelu admina, oni mogą się dodać poprzez ekran  rejestrowania nowego usera do którego mogą dostać maila.</p>
            <p>Super Admin ma prawo wysyłać linki rejestracyjne dla nowych userów.</p>
        </Typography>
    </div>
);

export default UserAside;