import * as React from "react";
import { ShowButton as ShowBTN } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    fontWeight: "bold",
    color: "#bada55",
    // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
    "& svg": { color: "#bada55" },
  },
});

const ShowButton = (props) => {
  const classes = useStyles();
  return <ShowBTN className={classes.button} {...props} />;
};
export default ShowButton;
