import * as React from "react";
import { EditButton as Edit } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    fontWeight: "bold",
    color: "orange",
    // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
    "& svg": { color: "orange" },
  },
});

const EditButton = (props) => {
  const classes = useStyles();
  return <Edit className={classes.button} {...props} />;
};
export default EditButton;
