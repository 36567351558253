const jsonTheme = {
    base00: "#424242", //Default Background
    base01: "white", //Lighter Background (Used for status bars, line number and folding marks)
    base02: "#ffffffb3", //Selection Background
    base03: "white", //Comments, Invisibles, Line Highlighting
    base04: "yellow", //Dark Foreground (Used for status bars)
    base05: "#7a7878", //Default Foreground, Caret, Delimiters, Operators
    base06: "rgb(252, 146, 158)", //Light Foreground (Not often used)
    base07: "rgb(252, 146, 158)", //Light Background (Not often used)
    base08: "white", //Variables, XML Tags, Markup Link Text, Markup Lists, Diff Deleted
    base09: "#ffffffb3", //Integers, Boolean, Constants, XML Attributes, Markup Link Url
    base0A: "#262626", //Classes, Markup Bold, Search Text Background
    base0B: "white", //Strings, Inherited Class, Markup Code, Diff Inserted
    base0C: "white", //Support, Regular Expressions, Escape Characters, Markup Quotes
    base0D: "white", //Functions, Methods, Attribute IDs, Headings
    base0E: "#6c8772", //Keywords, Storage, Selector, Markup Italic, Diff Changed
    base0F: "blue" //Deprecated, Opening/Closing Embedded Language Tags, e.g. <?php ?>
};

export default jsonTheme;