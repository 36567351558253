import * as React from "react";
import { cloneElement } from "react";
import {

    Datagrid,
    ListBase,
    FunctionField,
    ReferenceField,
    TextField,
} from 'react-admin';
import Card from '@material-ui/core/Card';


export const WidgetList = (props) => {
  const labelText = props.label
    ? props.label.charAt(0).toUpperCase() + props.label.slice(1)
    : props.content.charAt(0).toUpperCase() + props.content.slice(1);
  return (
    <CustomList
      {...props}
      basePath={props.content}
      resource={props.content}
      perPage={5}
      sort={{
        field: "createdate",
        order: "DESC",
      }}
    >
      <>
        <h2>Latest {props.label ? props.label : props.content}</h2>
        <Datagrid>
          <ReferenceField
            label={labelText}
            source="id"
            reference={props.content}
            sortable={false}
          >
            <TextField source={props.source} />
          </ReferenceField>
          <FunctionField
            label="Created at"
            render={(record) =>
              record.createdate
                ? new Date(record.createdate.seconds * 1000).toLocaleString()
                : "N/A"
            }
          />
        </Datagrid>
      </>
    </CustomList>
  );
};

const CustomList = ({ children, ...props }) => {
  return (
    <ListBase {...props}>
      <Card>
        {cloneElement(children, {
          hasBulkActions: props.bulkActionButtons !== false,
        })}
      </Card>
    </ListBase>
  );
};
