import * as React from "react";
import { Button } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    button: {
        fontWeight: "bold",
        color: "#34b7eb",
        "& svg": { color: "#34b7eb" },
    },
});

const CustomButton = ( {record, ...props} ) => {
    const classes = useStyles();
    return (
        <Button
            className={classes.button}
            onClick={e => props.handleClick(e, record)}
            label={props.innerText}
        >
            {props.children}
        </Button>
    )
};
export default CustomButton;