import {Filter, TextInput} from "react-admin";
import React from "react";

const VendorFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Code" source="code"/>
    </Filter>
);

export default VendorFilter;


