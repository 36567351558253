import React from "react";
import {
    Tab,
    Show,
    TextField,
    TabbedShowLayout,
    DateField,
    NumberField,
    ArrayField,
    Datagrid,
    SimpleShowLayout,
    EmailField,
    ReferenceField
} from "react-admin";
import OrderAside from "./OrderAside";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        "& .MuiFormLabel-root": {
            color: "white",
            fontWeight: 700
        },
        "& .MuiTableSortLabel-root": {
            color: "white",
            fontWeight: 700
        },
        "& .MuiFormControl-root": {
            marginRight: 15
        },
        "& .MuiCardContent-root": {
            padding: 0
        },
        "& .MuiTable-root": {
            marginBottom: 16
        }
    }
}));

const AddressTabbedInfo = (props) => {
    return (
        <SimpleShowLayout {...props}>
            <TextField source={`${props.role}.name`} label="Company"/>
            <TextField source={`${props.role}.name`} label="Name"/>
            <TextField source={`${props.role}.address_line_1`} label="Address"/>
            <TextField source={`${props.role}.zip_code`} label="Zip code"/>
            <TextField source={`${props.role}.city`} label="City"/>
            <TextField source={`${props.role}.country`} label="Country"/>
            <EmailField source={`${props.role}.email`} label="Email"/>
            <NumberField source={`${props.role}.tel`} label="Tel"/>
        </SimpleShowLayout>
    )
}

const OrderShow = (props) => {
    const classes = useStyles();
    return (
        <Show
            title="Order preview"
            aside={<OrderAside />}
            {...props}
        >
            <TabbedShowLayout className={classes.root}>
                <Tab label="MAIN">
                    <TextField source="id" />
                    <DateField source="createdate" label="Created at"/>
                    <TextField source="createdby" label="Created by"/>
                    <TextField source="ref_order" label="Ref order"/>
                    <ReferenceField
                        source="internal_routing.contract_number"
                        reference="clients"
                        label="Contract number"
                    >
                        <TextField source="contract_number" />
                    </ReferenceField>
                </Tab>
                <Tab label="SENDER">
                    <AddressTabbedInfo role="sender" {...props} />
                </Tab>
                <Tab label="RECEIVER">
                    <AddressTabbedInfo role="receiver" {...props} />
                </Tab>
                <Tab label="PACKAGES">
                    <TextField source="packages.length" label="Quantity" />
                    <ArrayField source="packages" addLabel={false}>
                        <Datagrid>
                            <TextField source="ref_number" label="Ref number" emptyText="not provided"/>
                            <NumberField source="weight" label="Weight"/>
                            <NumberField source="size_l" label="Size L"/>
                            <NumberField source="size_w" label="Size W"/>
                            <NumberField source="size_d" label="Size D"/>
                            <TextField source="content" multiline label="Content"/>
                            <NumberField source="value" label="Value"/>
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default OrderShow;