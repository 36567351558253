import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title } from "react-admin";

const NotFound = () => (
  <Card>
    <Title title="Wrzuć na luz" />
    <CardContent>
      <h1>404: Strony której szukasz nie znaleziono...</h1>
    </CardContent>
  </Card>
);
export default NotFound;
