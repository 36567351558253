import React from "react";
import { Create, SimpleForm, TextInput, DateInput } from "react-admin";

const PostCreate = (props) => {
  return (
    <Create title="Tworzenie artykułu" {...props}>
      <SimpleForm>
        <TextInput label="Tytuł" source="title" />
        <TextInput label="Treść" multiline source="body" />
        <DateInput label="Opublikowano" source="publishedAt" />
      </SimpleForm>
    </Create>
  );
};

export default PostCreate;
