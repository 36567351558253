import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
    SimpleFormIterator,
    ArrayInput,
    required,
    minLength,
    maxLength,
    minValue,
    number,
    maxValue,
    useNotify,
    useRedirect
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    sizeInput: {
        width: 150,
    },
    customInput: {
        float: "left",
        width: 80,
        marginRight: "10px"
    },
    customLabel: {
        float: "left",
        width: 80,
        textAlign: "center",
        lineHeight: "65px",
    },
    customMainLabel: {
        marginBottom: "10px",
        display: "block",
        // textDecoration: "underline wavy"
    }
}));
//inputs validation
const message = "please enter a value";
const validateName = [required(message), minLength(1), maxLength(40)];
const validateRequired = [required(message)];
const validateDescription = [required(message), minLength(1), maxLength(200)];
const validateMultiFrom = [required(message), minValue(2), number()];
const validateNumbers = [required(message), minValue(0), maxValue(999), number()];

const MatrixEdit = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify(`Changes in matrix saved`)
        redirect('/matrices');
    };

  return (
    <Edit
        title="Edycja matrycy"
        warnWhenUnsavedChanges
        onSuccess={onSuccess}
        undoable={false}
        {...props}
    >
      <SimpleForm submitOnEnter={false}>
        <TextInput
            label="ID"
            source="id"
            disabled
        />
          <DateInput
              label="Create date"
              source="createdate"
              disabled
          />
          <TextInput
              label="Created by"
              source="createdby"
              disabled
          />

          <TextInput
              source="name"
              validate={validateName}
          />
          <TextInput
              source="display_name"
              validate={validateName}
          />
          <ReferenceInput
              label="Contract"
              source="contract_number"
              reference="clients"
          >
              <SelectInput
                  optionText="display_name"
                  validate={validateRequired}
              />
          </ReferenceInput>
          <ReferenceInput
              label="Non-standard vendor"
              source="nst_vendor_id"
              reference="vendors"
          >
              <SelectInput
                  optionText="code"
                  validate={validateRequired}
              />
          </ReferenceInput>
          <TextInput
              source="description"
              multiline
              resettable
              validate={validateDescription}
          />
          <NumberInput
              source="multi_from"
              validate={validateMultiFrom}
          />
          <WeightsForm />
          <CountriesForm />
      </SimpleForm>
    </Edit>
  );
};

const WeightsForm = () => {
    return (
        <ArrayInput
            source="weights"
            validate={validateRequired}
        >
            <SimpleFormIterator>
                <NumberInput
                    initialValue={0}
                    label="from"
                    source="from"
                    validate={validateNumbers}
                />
                <NumberInput
                    initialValue={0}
                    label="to"
                    source="to"
                    validate={validateNumbers}
                />
                <ReferenceInput
                    label="vendor"
                    source="vendor_id"
                    reference="vendors"
                >
                    <SelectInput
                        optionText="code"
                        validate={validateRequired}
                    />
                </ReferenceInput>
            </SimpleFormIterator>
        </ArrayInput>
    );
};

const CountriesForm = () => {
    const classes = useStyles();
    const countries = [
        {
            id: "FR",
            name: "France",
        },
        {
            id: "DE",
            name: "Germany",
        },
        {
            id: "PL",
            name: "Poland",
        },
        {
            id: "CZ",
            name: "Czech Republic"
        },
        {
            id: "NL",
            name: "Netherlands"
        },
        {
            id: "UA",
            name: "Ukraine"
        }
    ];

    return (
        <ArrayInput
            source="countries"
            validate={validateRequired}
        >
            <SimpleFormIterator>
                <SelectInput
                    label="country"
                    source="country_code"
                    choices={countries}
                    validate={validateRequired}
                />
                <span
                    className={classes.customMainLabel}
                >
                STANDARD
                </span>
                <span
                    className={classes.customLabel}
                >
                Size D
                </span>
                <NumberInput
                    initialValue={0}
                    label="min"
                    source="std.size_d.min"
                    validate={validateNumbers}
                    className={classes.customInput}
                />
                <NumberInput
                    initialValue={0}
                    label="max"
                    source="std.size_d.max"
                    validate={validateNumbers}
                    className={classes.customInput}
                />
                <div style={{ display: "block", clear: "both" }} />
                <span
                    className={classes.customLabel}
                >
                Size L
                </span>
                <NumberInput
                    initialValue={0}
                    label="min"
                    source="std.size_l.min"
                    validate={validateNumbers}
                    className={classes.customInput}
                />
                <NumberInput
                    initialValue={0}
                    label="max"
                    source="std.size_l.max"
                    validate={validateNumbers}
                    className={classes.customInput}
                />
                <div style={{ display: "block", clear: "both" }} />
                <span
                    className={classes.customLabel}
                >
                Size W
                </span>
                <NumberInput
                    initialValue={0}
                    label="min"
                    source="std.size_w.min"
                    validate={validateNumbers}
                    className={classes.customInput}
                />
                <NumberInput
                    initialValue={0}
                    label="max"
                    source="std.size_w.max"
                    validate={validateNumbers}
                    className={classes.customInput}
                />
                <div style={{ display: "block", clear: "both" }} />
                <span
                    className={classes.customMainLabel}
                >
                NON-STANDARD
                </span>
                <span
                    className={classes.customLabel}
                >
                Size D
                </span>
                <NumberInput
                    initialValue={0}
                    label="min"
                    source="nst.size_d.min"
                    validate={validateNumbers}
                    className={classes.customInput}
                />
                <NumberInput
                    initialValue={0}
                    label="max"
                    source="nst.size_d.max"
                    validate={validateNumbers}
                    className={classes.customInput}
                />
                <div style={{ display: "block", clear: "both" }} />
                <span
                    className={classes.customLabel}
                >
                Size L
                </span>
                <NumberInput
                    initialValue={0}
                    label="min"
                    source="nst.size_l.min"
                    validate={validateNumbers}
                    className={classes.customInput}
                />
                <NumberInput
                    initialValue={0}
                    label="max"
                    source="nst.size_l.max"
                    validate={validateNumbers}
                    className={classes.customInput}
                />
                <div style={{ display: "block", clear: "both" }} />
                <span
                    className={classes.customLabel}
                >
                Size W
                </span>
                <NumberInput
                    initialValue={0}
                    label="min"
                    source="nst.size_w.min"
                    validate={validateNumbers}
                    className={classes.customInput}
                />
                <NumberInput
                    initialValue={0}
                    label="max"
                    source="nst.size_w.max"
                    validate={validateNumbers}
                    className={classes.customInput}
                />
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default MatrixEdit;
