import {
    ArrayField,
    Datagrid,
    DateField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField
} from "react-admin";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import LoggingAside from "./LoggingAside";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        "& .MuiFormLabel-root": {
            color: "white",
            fontWeight: 700
        },
        "& .MuiTableSortLabel-root": {
            color: "white",
            fontWeight: 700
        },
        "& .MuiFormControl-root": {
            marginRight: 15
        },
        "& .MuiCardContent-root": {
            padding: 0
        },
        "& .MuiTable-root": {
            marginBottom: 16
        }
    }
}));

const LoggingShow = (props) => {
    const classes = useStyles();
    return (
        <Show
            title="Logg preview"
            aside={<LoggingAside />}
            {...props}
        >
            <TabbedShowLayout className={classes.root}>
                <Tab label="MAIN">
                    <TextField source="id"/>
                    <DateField source="createdate" label="Created at" showTime/>
                    <TextField source="createdby" label="Created by"/>
                    <TextField source="message"/>
                </Tab>
                <Tab label="DETAILS">
                    <ArrayField source="details" addLabel={false}>
                        <Datagrid>
                            <TextField source="data"/>
                            <TextField source="label"/>
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};

export default LoggingShow;
