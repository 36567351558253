import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import { EditButton, ShowButton, DeleteButton } from "../../ui/Button";
import MatrixDetails from "./MatrixDetails";
import MatrixFilter from "./MatrixFilter";

const MatrixList = (props) => {
  return (
    <List
        {...props}
        filters={<MatrixFilter />}
        exporter={false}
    >
      <Datagrid rowClick="expand" expand={MatrixDetails}>
        <TextField source="contract_number" />
        <TextField source="display_name" />
        <TextField source="description" sortable={false} />
        <ShowButton basePath="/matrices" />
        <EditButton basePath="/matrices" />
        <DeleteButton basePath="/matrices" />
      </Datagrid>
    </List>
  );
};

export default MatrixList;
