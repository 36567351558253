import React from "react";
import {
    Tab,
    Show,
    TextField,
    TabbedShowLayout,
    DateField,
    ReferenceField
} from "react-admin";
import ClientAside from "./ClientAside";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        "& .MuiFormLabel-root": {
            color: "white",
            fontWeight: 700
        },
        "& .MuiTableSortLabel-root": {
            color: "white",
            fontWeight: 700
        },
        "& .MuiFormControl-root": {
            marginRight: 15
        },
        "& .MuiCardContent-root": {
            padding: 0
        },
        "& .MuiTable-root": {
            marginBottom: 16
        }
    }
}));

const ClientShow = (props) => {
  const classes = useStyles();
  return (
      <Show
          title="Client preview"
          aside={<ClientAside />}
          {...props}
      >
          <TabbedShowLayout className={classes.root}>
              <Tab label="MAIN">
                  <TextField source="id" />
                  <DateField source="createdate" label="Created at" showTime/>
                  <TextField source="createdby" label="Created by"/>
                  <TextField source="display_name" label="Name"/>
                  <TextField source="contract_number" label="Contract number"/>
                  <ReferenceField
                      source="matrix_id"
                      reference="matrices"
                      label="Matrix ID"
                  >
                      <TextField source="id" />
                  </ReferenceField>
                  <DateField source="lastupdate" label="Last update" showTime emptyText="no update"/>
                  <TextField source="updatedby" label="Update by" emptyText="no update"/>
              </Tab>
          </TabbedShowLayout>
      </Show>
  )
};

export default ClientShow;