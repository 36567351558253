import React from 'react';
import {
    TextField,
    SimpleShowLayout,
    DateField,
    EmailField
} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "& .MuiFormLabel-root": {
            color: "white",
            fontWeight: 700
        },
        "& .MuiTableSortLabel-root": {
            color: "white",
            fontWeight: 700
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        textAlign: "left",
        color: theme.palette.text.secondary,
    },
}));

const PrimaryInfo = (props) => {
    const classes = useStyles();
    return (
        <Grid item xs={6}>
            <Paper className={classes.paper}>
                <h2>Info</h2>
                <SimpleShowLayout {...props}>
                    <TextField source="id" />
                    <DateField source="createdate" label="Created at" showTime/>
                    <TextField source="createdby" label="Created by"/>
                    <DateField source="lastupdate" label="Last update" showTime emptyText="no update"/>
                    <TextField source="updatedby" label="Update by" emptyText="no update"/>
                </SimpleShowLayout>
            </Paper>
        </Grid>
    )
}

const DetailsInfo = (props) => {
    const classes = useStyles();
    return (
        <Grid item xs={6}>
            <Paper className={classes.paper}>
                <h2>Details</h2>
                <SimpleShowLayout {...props}>
                    <TextField source="firstName" label="First name" emptyText="not provided"/>
                    <TextField source="lastName" label="Last name" emptyText="not provided"/>
                    <TextField source="role" emptyText="not provided"/>
                    <EmailField source="email" />
                </SimpleShowLayout>
            </Paper>
        </Grid>
    )
}

const UserDetails = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <PrimaryInfo {...props} />
                <DetailsInfo {...props} />
            </Grid>
        </div>
    );
};

export default UserDetails;