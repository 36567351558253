import {
  TextField,
  SimpleShowLayout,
  DateField,
  NumberField,
  FunctionField,
  EmailField,
  ArrayField,
  Datagrid,
  ReferenceField
} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiFormLabel-root": {
      color: "white",
      fontWeight: 700
    },
    "& .MuiTableSortLabel-root": {
      color: "white",
      fontWeight: 700
    }
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

const PrimaryInfo = (props) => {
  const classes = useStyles();
  return (
      <Grid item xs={3}>
        <Paper className={classes.paper }>
          <h2>Info</h2>
          <SimpleShowLayout {...props}>
            <TextField source="id" />
            <DateField source="createdate" label="Created at"/>
            <TextField source="createdby" label="Created by"/>
            <TextField source="ref_order" label="Ref order" emptyText="not provided"/>
            <ReferenceField
                source="internal_routing.contract_number"
                reference="clients"
                label="Contract number"
            >
              <TextField source="contract_number" />
            </ReferenceField>
          </SimpleShowLayout>
        </Paper>
      </Grid>
  );
}

const AddressInfo = (props) => {
  const classes = useStyles();
  return (
      <Grid item xs={3}>
        <Paper className={classes.paper}>
          <h2>{props.role.charAt(0).toUpperCase() + props.role.slice(1)}</h2>
          <SimpleShowLayout {...props}>
            <TextField source={`${props.role}.company`} label="Company"/>
            <TextField source={`${props.role}.name`} label="Name"/>
            <FunctionField label="Address"
               render={record =>
                   `
                   ${record[`${props.role}`].address_line_1}
                   ${record[`${props.role}`].zip_code}
                   ${record[`${props.role}`].city}
                   ${record[`${props.role}`].country}
                   `
               }
            />
            <EmailField source={`${props.role}.email`} label="Email"/>
            <NumberField source={`${props.role}.tel`} label="Tel"/>
          </SimpleShowLayout>
        </Paper>
      </Grid>
  );
}

const PackagesInfo = (props) => {
  const classes = useStyles();

  return (
      <Grid item xs={6}>
        <Paper className={classes.paper}>
          <h2>Packages</h2>
          <SimpleShowLayout {...props}>
            <TextField source="packages.length" label="Quantity" />
            <ArrayField source="packages" addLabel={false}>
              <Datagrid>
                <TextField source="ref_number" label="Ref number" emptyText="not provided"/>
                <NumberField source="weight" label="Weight"/>
                <NumberField source="size_l" label="Size L"/>
                <NumberField source="size_w" label="Size W"/>
                <NumberField source="size_d" label="Size D"/>
                <TextField source="content" multiline label="Content"/>
                <NumberField source="value" label="Value"/>
              </Datagrid>
            </ArrayField>
          </SimpleShowLayout>
        </Paper>
      </Grid>
  );
};

const OrderDetails = (props) => {
  const classes = useStyles();
  return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <PrimaryInfo {...props} />
          <AddressInfo {...props} role="sender"/>
          <AddressInfo {...props} role="receiver"/>
          <PackagesInfo {...props} />
        </Grid>
      </div>
  );
};

export default OrderDetails;
