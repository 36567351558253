import React from 'react';
import {
    Filter,
    TextInput,
    DateInput
} from "react-admin";

const UserFilter = (props) => {
    const dateFormatter = value => {
        const newDate = new Date(value);
        const dateString = newDate.toString();
        const dateSplit = dateString.split(" ");
        return `${dateSplit[0]} ${dateSplit[1]} ${dateSplit[2]} ${dateSplit[3]}`;
    };
    return (
        <Filter {...props}>
            <DateInput
                label="Create date"
                source="createdate"
                parse={dateFormatter}
            />
            <TextInput label="Created by" source="createdby" />
            <TextInput source="role" />
        </Filter>
    );
};

export default UserFilter;