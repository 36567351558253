import React from "react";
import {
    Tab,
    Show,
    TextField,
    TabbedShowLayout,
    DateField,
    ReferenceField,
    FunctionField,
    NumberField,
    ArrayField,
    Datagrid
} from "react-admin";
import ParcelAside from "./ParcelAside";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "& .MuiFormLabel-root": {
            color: "white",
            fontWeight: 700
        },
        "& .MuiTableSortLabel-root": {
            color: "white",
            fontWeight: 700
        },
        "& .MuiFormControl-root": {
            marginRight: 15
        }
    },
    row: {
        display: "flex"
    }
}));

const ParcelShow = (props) => {
    const classes = useStyles();
    return (
        <Show
            title="Parcel preview"
            aside={<ParcelAside />}
            {...props}
        >
            <TabbedShowLayout className={classes.root}>
                <Tab label="MAIN">
                    <TextField source="id" />
                    <DateField source="createdate" label="Created at"/>
                    <TextField source="createdby" label="Created by"/>
                    <ReferenceField source="order" reference="orders">
                        <TextField source="id" />
                    </ReferenceField>
                    <FunctionField
                        label="Latest status"
                        render={record =>
                            `${record.latest_status.status}: ${record.latest_status.message}`
                        }
                    />
                </Tab>

                <Tab label="DETAILS">
                    <TextField source="details.ref_number" label="Ref number"/>
                    <NumberField source="details.weight" label="Weight"/>
                    <TextField source="details.content" multiline label="Content"/>
                    <NumberField source="details.value" label="Value"/>
                </Tab>

                <Tab label="SIZE" contentClassName={classes.row}>
                    <NumberField source="details.size_l" label="Size L" />
                    <NumberField source="details.size_w" label="Size W" />
                    <NumberField source="details.size_d" label="Size D"/>
                </Tab>

                <Tab label="HISTORY" >
                    <ArrayField source="history">
                        <Datagrid>
                            <TextField source="status" />
                            <TextField source="message" />
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="TRACKING">
                    <ArrayField source="tracking">
                        <Datagrid>
                            <TextField source="label" />
                            <TextField source="number_1" label="Number"/>
                            <TextField source="type" />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default ParcelShow;