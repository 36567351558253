import React from "react";
import {
    Filter,
    TextInput,
    DateInput
} from "react-admin";

const OrderFilter = (props) => {
   const dateFormatter = value => {
      const newDate = new Date(value);
      const dateString = newDate.toString();
      const dateSplit = dateString.split(" ");
      return `${dateSplit[0]} ${dateSplit[1]} ${dateSplit[2]} ${dateSplit[3]}`;
    };

   return (
       <Filter {...props}>
           <DateInput
               label="Create date"
               source="createdate"
               parse={dateFormatter}
           />
           <TextInput label="Created by" source="createdby" />
           <TextInput label="Contract number" source="internal_routing.contract_number" />
           <TextInput label="Ref number" source="ref_order" />
       </Filter>
   );
};

export default OrderFilter;
