import React from "react";
import {
  Datagrid,
  List,
  TextField,
} from "react-admin";
import { EditButton, ShowButton, DeleteButton } from "../../ui/Button";
import VendorDetails from "./VendorDetails";
import VendorFilter from "./VendorFilter";

const VendorList = (props) => {
  return (
    <List {...props} filters={<VendorFilter />} exporter={false}>
      <Datagrid rowClick="expand" expand={VendorDetails}>
        <TextField source="code" />
        <TextField source="display_name" />
        <TextField source="description" sortable={false} />
        <ShowButton basePath="/vendors" />
        <EditButton basePath="/vendors" />
        <DeleteButton basePath="/vendors" />
      </Datagrid>
    </List>
  );
};

export default VendorList;
