import React from "react";
import {
  Datagrid,
  List,
  TextField,
  FunctionField, DateField
} from "react-admin";
import { ShowButton } from "../../ui/Button";
import UserFilter from "./UserFilter";
import UserDetails from "./UserDetails";

const UserList = (props) => {
  return (
    <List
        {...props}
        filters={<UserFilter />}
        exporter={false}
        sort={{ field: 'role', order: 'DESC' }}
    >
      <Datagrid
          rowClick="expand"
          expand={UserDetails}
      >
        <FunctionField
            label="Name and last name"
            render={record => `${record.firstName} ${record.lastName}`}
        />
        <TextField source="role" />
        <DateField source="createdate" label="Create date" showTime/>
        <TextField source="createdby" label="Created by"/>
        <ShowButton basePath="/users" />
      </Datagrid>
    </List>
  );
};

export default UserList;